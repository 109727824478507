import React from 'react';
import PropTypes from 'prop-types';
import NextHead from 'next/head';

import { library, config } from '@fortawesome/fontawesome-svg-core';
// import { fab } from '@fortawesome/free-brands-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { colors, mainLayoutColor } from '../lib/theme';

// NOTE this example only shows how to use the solid free icons.
// import { faCoffee, faIgloo, faVolumeUp, faCheckSquare, faScrewdriver, faUser } from '@fortawesome/free-solid-svg-icons';
import {
  faHeadphones, faHeart, faSearch, faChartLine, faAd, faSlash, faListUl, faEnvelope, faLandmark, faPeopleCarry, faFutbol, faDice,
  faUserAstronaut, faUniversity, faBriefcase, faPaintBrush, faTheaterMasks, faDumbbell, faNewspaper, faFlask, faUserSecret, faRocket,
  faTv, faMusic, faBabyCarriage, faArrowLeft, faBars, faTimes, faPlay, faPause, faVolumeUp, faSpinner, faShareSquare, faCloudDownloadAlt, faInfoCircle, faExternalLinkAlt,
  faStar, faBookmark as faBookmark2, faFolder, faFolderOpen, faSlidersH,
  faCaretRight,
  faCheck,
  faBrain,
  faDollarSign,
  faSmile,
  faAppleAlt,
  faFistRaised,
  faLightbulb,
  faLevelUpAlt,
  faBookOpen,
  faCheckCircle,
  faSignInAlt,
  faVideo
} from '@fortawesome/free-solid-svg-icons';
import { faBookmark, faHeart as faHeart2 } from '@fortawesome/free-regular-svg-icons';
import { faTwitter, faDiscord, faReddit } from '@fortawesome/free-brands-svg-icons';

config.autoAddCss = true;
library.add(faHeadphones, faHeart, faSearch, faChartLine, faAd, faSlash, faListUl, faTwitter, faDiscord, faEnvelope, faReddit,
  faLandmark, faPeopleCarry, faFutbol, faDice, faUserAstronaut, faUniversity, faBriefcase, faPaintBrush, faTheaterMasks,
  faDumbbell, faNewspaper, faFlask, faUserSecret, faRocket, faTv, faMusic, faBabyCarriage, faArrowLeft, faBars, faTimes,
  faPlay, faPause, faVolumeUp, faSpinner, faShareSquare, faHeart, faHeart2, faCloudDownloadAlt, faInfoCircle, faExternalLinkAlt,
  faBookmark, faBookmark2, faStar, faFolder, faFolderOpen, faSlidersH, faCaretRight, faCheck, faCheckCircle,
  faBrain, faDollarSign, faChartLine, faSmile, faAppleAlt, faFistRaised, faHeart, faLightbulb, faLevelUpAlt, faFistRaised, faBookOpen,
  faSignInAlt, faShareSquare, faVideo
);

// const defaultTitle = 'Home of Podcasts';
// const defaultDescription = '';
// const defaultOGURL = '';
// const defaultOGImage = '';

function Head(props) {
  // console.dir(props.settingsState);
  return (
    <NextHead>
      <meta charSet="UTF-8" />
      {/* <title>{props.title || ''}</title>
      <meta name="description" content={props.description || defaultDescription} /> */}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="ahrefs-site-verification" content="e393be41bf92127195fc76c085c3fd693b9bc7830e6382f25b02451250c7d5ca"></meta>
      <link rel="icon" href="/static/favicon.png" />
      <meta httpEquiv="content-language" content="en-us"></meta>
      <meta name="ahrefs-site-verification" content="d0a2a07fb9ee76c5944cb334fae983f9a748b869d8b19296ea8cfd000a853edc"></meta>
      <meta name="fo-verify" content="ccef6736-5300-46c6-8041-d1ba9a14639d"></meta>
      <meta name="apple-itunes-app" content="app-id=6477714480"></meta>
      {/* <meta property="og:url" content={props.url || defaultOGURL} />
      <meta property="og:title" content={props.title || defaultTitle} />
      <meta property="og:description" content={props.description || defaultDescription} /> */}
      {/* <meta name="twitter:site" content={props.url || defaultOGURL} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:image" content={props.ogImage || defaultOGImage} />
    <meta property="og:image" content={props.ogImage || defaultOGImage} /> */}
      {/* <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" /> */}

      {/* <link rel='stylesheet' type='text/css' href='/static/css/nprogress.css' /> */}
      {/* <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/latest/css/bootstrap.min.css" /> */}
      {/* <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i" rel="stylesheet"></link> */}
      {/* <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,500,400i" rel="stylesheet"></link> */}
      <link rel="preconnect" href="https://fonts.gstatic.com/"></link>
      <link href="https://fonts.googleapis.com/css2?family=PT+Serif&family=Quicksand:wght@500&family=Merriweather:wght@500&display=swap" rel="stylesheet"></link>
      {/* <link href="https://fonts.googleapis.com/css?family=Quicksand:500&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=PT+Serif&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@500;600&display=swap" rel="stylesheet"></link> */}
      {/* <link href="https://fonts.googleapis.com/css?family=Quicksand:500" rel="stylesheet"></link> */}
      {/* <link href="https://fonts.googleapis.com/css?family=Chivo:300,700|Playfair+Display:500" rel="stylesheet"></link> */}
      {/* <link href="https://fonts.googleapis.com/css?family=Poppins:400,500&display=swap" rel="stylesheet"></link> */}
      <link rel="stylesheet" href="https://cdn.plyr.io/3.6.8/plyr.css" />

      {/* <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}');
          `
        }}
      /> */}
      {/* <script defer 
        dangerouslySetInnerHTML={{
          __html: `
                            (function(h,o,t,j,a,r){
                                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                h._hjSettings={hjid:1327055,hjsv:6};
                                a=o.getElementsByTagName('head')[0];
                                r=o.createElement('script');r.async=1;
                                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                a.appendChild(r);
                            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `
        }}
      /> */}

      {/* <script charset="UTF-8" src='/static/js/clamp.min.js' async></script> */}
      <style global>{`
        body { 
          // background: black !important; // ${mainLayoutColor};
          // font: 11px menlo;
          // color: #fff;
          // font-family: 'Open Sans', sans-serif;
          // font-family: 'Poppins', sans-serif;
          // font-family: 'Quicksand', sans-serif;
          // font-weight: 300;
        }

        .separator {
          height: 1px;
          width: 100%;
          color: ${colors.primaryDim};
          background-color: ${colors.primaryDim};
          border: none;
        }
        h1 {
          font-family: 'Quicksand', sans-serif;
          // font-family: 'Playfair Display', serif;
          font-weight: 500;
        }
        h2 {
          font-family: 'Quicksand', sans-serif;
          // font-family: 'Playfair Display', serif;
          font-weight: 500;
          font-size: 2em;
        }
        h4 {
          // font-family: 'Montserrat Sans', sans-serif;
          font-family: 'Merriweather', serif;
        }
        p {
          // font-family: 'Montserrat Sans', sans-serif;
          font-family: 'Merriweather', serif;
        }
        h1.dark,h2.dark,h3.dark {
          color: #030028 !important;
        }
        .dark h1, .dark h2, .dark h3 {
          color: #030028 !important;
        }
        h4.dark, h5.dark,p.dark {
          color: #030028dd !important;
        }
        .dark h4, .dark h5, .dark p {
          color: #030028dd !important;
        }
        .quicksand {
          font-family: 'Quicksand', sans-serif !important;
        }
        a {
          font-family: 'Quicksand', sans-serif;
        }
        .serif {
          font-family: 'Merriweather', serif;
        }
        .serif-bold {
          font-family: 'Zilla Slab', serif;
          font-weight: 500;
        }
        .serif-bold-extra {
          font-family: 'Zilla Slab', serif;
          font-weight: 500;
        }
        // html {
        //   background: ${mainLayoutColor};
        // }
        :global(a){
          // color: ${colors.primary} !important;
        }
        .text-center {
          text-align: center;
        }
        .app-btn-primary{
          background-color: ${colors.third} !important;
          border: 1px solid;
          border-color: ${colors.third};
          color: white;
        }
        .app-btn-primary:hover {
          background-color: #3199CD1f !important;
          // border-color: #3199CD1f;
          color: ${colors.third};
        }
        .app-btn-outline {
          color: ${colors.third} !important;
          border: 1px solid;
          border-color: ${colors.third};
        }
        .app-btn-outline:hover {
          background-color: #3199CD1f !important;
          color: ${colors.third};
        }

        .ant-btn {
          border-radius: 6px !important;
        }
        .shadow {
          box-shadow: 0 20px 40px 0 rgba(0,11,40,.14);
        }
          .mb-2 {
          margin-bottom: 1em;
          }
          .mb-3 {
          margin-bottom: 2em;
          }
          .intro-bg {
          background: #fefdf7;
          }

        .fadeInTop { animation: fadeInTop 1s };
        @-webkit-keyframes fadeInTop {
            from {
                opacity: 0;
                transform: translateY(-20%);
            }
            to { opacity: 1 }
        }
        @keyframes fadeInTop {
            from {
                opacity: 0;
                transform: translateY(-20%);
            }
            to { opacity: 1 }
        }

        .fadeInBottom { animation: fadeInBottom 1s };
        @-webkit-keyframes fadeInBottom {
            from {
                opacity: 0;
                transform: translateY(5%);
            }
            to { opacity: 1 }
        }
        @keyframes fadeInBottom {
            from {
                opacity: 0;
                transform: translateY(5%);
            }
            to { opacity: 1 }
        }
      `}</style>
    </NextHead>
  );
}

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  settingsState: PropTypes.object
}

// const mapStateToProps = (state) => {
//   return {
//     settingsState: state.settingsState,
//   }
// }

export default Head;
// export default connect(mapStateToProps, null)(Head);
